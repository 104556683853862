var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[(
      (_vm.$store.getters['app/approvalAccess']('KYC') ||
        _vm.$store.state.app.user_data.role == 'admin') &&
      (_vm.row.is_kyc == 0 || _vm.row.is_kyc == 2)
    )?_c('b-badge',{staticClass:"mr-50 cursor-pointer",attrs:{"variant":"primary","id":'recieptApprove' + _vm.row.id},on:{"click":function($event){return _vm.handleKYC(_vm.row, 'Approved')}}},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1):_vm._e(),(
      (_vm.$store.getters['app/approvalAccess']('KYC') ||
        _vm.$store.state.app.user_data.role == 'admin') &&
      (_vm.row.is_kyc == 0 || _vm.row.is_kyc == 2)
    )?_c('b-tooltip',{attrs:{"target":'recieptApprove' + _vm.row.id,"triggers":"hover"}},[_vm._v(" Approve ")]):_vm._e(),(
      (_vm.$store.getters['app/approvalAccess']('KYC') ||
        _vm.$store.state.app.user_data.role == 'admin') &&
      (_vm.row.is_kyc == 0 || _vm.row.is_kyc == 1)
    )?_c('b-badge',{staticClass:"mr-50 cursor-pointer",attrs:{"variant":"danger","id":'recieptReject' + _vm.row.id},on:{"click":function($event){return _vm.handleKYC(_vm.row, 'Rejected')}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1):_vm._e(),(
      (_vm.$store.getters['app/approvalAccess']('KYC') ||
        _vm.$store.state.app.user_data.role == 'admin') &&
      (_vm.row.is_kyc == 0 || _vm.row.is_kyc == 1)
    )?_c('b-tooltip',{attrs:{"target":'recieptReject' + _vm.row.id,"triggers":"hover"}},[_vm._v(" Reject ")]):_vm._e(),(
      _vm.$store.getters['app/approvalAccess']('KYC') ||
      _vm.$store.state.app.user_data.role == 'admin'
    )?_c('b-badge',{staticClass:"mr-50 cursor-pointer",attrs:{"variant":"success","id":'remarks' + _vm.row.id},on:{"click":function($event){return _vm.handleRemarks(_vm.row.id)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1):_vm._e(),(
      _vm.$store.getters['app/approvalAccess']('KYC') ||
      _vm.$store.state.app.user_data.role == 'admin'
    )?_c('b-tooltip',{attrs:{"id":'remarks' + _vm.row.id,"target":'remarks' + _vm.row.id,"triggers":"hover"}},[_vm._v(" Remarks ")]):_vm._e(),_c('b-modal',{attrs:{"id":"modal-form","cancel-variant":"outline-secondary","ok-title":"Submit","cancel-title":"Close","title":"Remarks","hide-footer":""},model:{value:(_vm.remarksPopUp),callback:function ($$v) {_vm.remarksPopUp=$$v},expression:"remarksPopUp"}},[_c('b-form-textarea',{attrs:{"placeholder":"Enter Remarks","rows":"5"},model:{value:(_vm.remarksForUpdate),callback:function ($$v) {_vm.remarksForUpdate=$$v},expression:"remarksForUpdate"}}),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitRemarksKyc($event)}}},[_vm._v(" Submit ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }