<template>
  <div class="d-flex">
    <b-badge
      v-if="
        ($store.getters['app/approvalAccess']('KYC') ||
          $store.state.app.user_data.role == 'admin') &&
        (row.is_kyc == 0 || row.is_kyc == 2)
      "
      variant="primary"
      :id="'recieptApprove' + row.id"
      class="mr-50 cursor-pointer"
      @click="handleKYC(row, 'Approved')"
    >
      <feather-icon icon="CheckIcon" />
    </b-badge>
    <b-tooltip
      v-if="
        ($store.getters['app/approvalAccess']('KYC') ||
          $store.state.app.user_data.role == 'admin') &&
        (row.is_kyc == 0 || row.is_kyc == 2)
      "
      :target="'recieptApprove' + row.id"
      triggers="hover"
    >
      Approve
    </b-tooltip>

    <b-badge
      v-if="
        ($store.getters['app/approvalAccess']('KYC') ||
          $store.state.app.user_data.role == 'admin') &&
        (row.is_kyc == 0 || row.is_kyc == 1)
      "
      variant="danger"
      :id="'recieptReject' + row.id"
      class="mr-50 cursor-pointer"
      @click="handleKYC(row, 'Rejected')"
    >
      <feather-icon icon="XIcon" />
    </b-badge>

    <b-tooltip
      v-if="
        ($store.getters['app/approvalAccess']('KYC') ||
          $store.state.app.user_data.role == 'admin') &&
        (row.is_kyc == 0 || row.is_kyc == 1)
      "
      :target="'recieptReject' + row.id"
      triggers="hover"
    >
      Reject
    </b-tooltip>

    <b-badge
      v-if="
        $store.getters['app/approvalAccess']('KYC') ||
        $store.state.app.user_data.role == 'admin'
      "
      variant="success"
      :id="'remarks' + row.id"
      class="mr-50 cursor-pointer"
      @click="handleRemarks(row.id)"
    >
      <feather-icon icon="PlusIcon" />
    </b-badge>

    <b-tooltip
      v-if="
        $store.getters['app/approvalAccess']('KYC') ||
        $store.state.app.user_data.role == 'admin'
      "
      :id="'remarks' + row.id"
      :target="'remarks' + row.id"
      triggers="hover"
    >
      Remarks
    </b-tooltip>

    <b-modal
      id="modal-form"
      v-model="remarksPopUp"
      cancel-variant="outline-secondary"
      ok-title="Submit"
      cancel-title="Close"
      title="Remarks"
      hide-footer
    >
      <b-form-textarea v-model="remarksForUpdate" placeholder="Enter Remarks" rows="5" />
      <b-button variant="primary" @click.prevent="submitRemarksKyc($event)" class="mt-2">
        Submit
      </b-button>
    </b-modal>
  </div>
</template>
<script>
import { BBadge, BTooltip, BModal, BFormTextarea, BButton } from "bootstrap-vue";
import axios from "@/components/axios";

export default {
  props: ["row", "getGridData"],
  components: { BBadge, BTooltip, BModal, BFormTextarea, BButton },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      webEndpoint: process.env.VUE_APP_WEBENDPOINT,
      accessToken: localStorage.getItem("accessToken"), // JSON.parse()
      remarksForUpdate: "",
      KycUserId: "",
      remarksPopUp: false,
    };
  },
  methods: {
    handleRemarks(id) {
      this.remarksPopUp = !this.remarksPopUp;
      this.KycUserId = id;
    },
    async submitRemarksKyc() {
      await axios({
        method: "PUT",
        url: `${this.baseApi}/auth/userupdate/${this.KycUserId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify({ specialremarks: this.remarksForUpdate }),
      })
        .then((json) => {
          this.$swal({
            title: "Submited",
            text: json.data.message
              ? `${json.data.message}`
              : json.data.success
              ? `${json.data.success}`
              : "Update SuccessFully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getGridData();
        })
        .catch((error) => {
          this.$swal({
            title: "Error!",
            text: `${error}`,
            icon: "error",
            timer: 5000,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .finally(() => {
          this.remarksPopUp = false;
          this.remarksForUpdate = "";
        });
    },
    async handleKYC(row, status) {
      this.$swal({
        title: `Are you sure to ${status == "Approved" ? "Approve" : "Reject"}?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `${status == "Approved" ? "Approve" : "Reject"}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          let url =
            row.role == "channelpartner"
              ? `${this.baseApi}/channelpartner/${row.id}`
              : `${this.baseApi}/franchise/${row.id}`;
          let data = {
            is_kyc: status == "Approved" ? 1 : 2,
          };

          await axios({
            method: "put",
            url: url,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              if (json.data.status == 200 || json.data) {
                this.$swal({
                  icon: "success",
                  title: `${status == "Approved" ? "Approved" : "Rejected"}`,
                  text: `Your data has been ${
                    status == "Approved" ? "Approved" : "Rejected"
                  }.`,
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                this.getGridData();
              }
            })
            .catch((error) =>
              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              })
            );
        }
      });
    },
  },
};
</script>
